<template>
  <div class="vx-row my-4">
    <div class="vx-col">
      <div class="vx-row mb-6">
        <div class="vx-col flex items-center justify-items-start gap-2">
          <vs-button
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-printer"
            @click="onClickExportToExcel"
          >
            Export to Excel</vs-button
          >
        </div>
      </div>
    </div>
    <div class="core vx-col md:w-1/1 w-full mb-base">
      <vs-table
        search
        stripe
        border
        multiple
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="onSearchTable"
        v-model="selected_rows"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th>Action</vs-th>
          <vs-th>No</vs-th>
          <vs-th>Collection Plan ID</vs-th>
          <vs-th>Sales / Collector</vs-th>
          <vs-th># of Invoice</vs-th>
          <vs-th>Remaining Value</vs-th>
          <vs-th># of Collection</vs-th>
          <vs-th>Collection Value</vs-th>
          <!-- <vs-th>Delta</vs-th> -->
          <vs-th># of no Payment</vs-th>
          <vs-th>no Payment Value</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="whitespace-no-wrap">
              <div class="vx-row gap-2">
                <!-- <vs-button
                  @click.stop="handleShow(data[indextr].id)"
                  size="small"
                  color="success"
                  icon-pack="feather"
                  icon="icon-eye"
                  title="Edit"
                  style="margin-right: 5px"
                /> -->
                <vs-button
                  @click="onClickPrint(data[indextr].id)"
                  size="small"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-printer"
                />
              </div>
            </vs-td>
            <vs-td>{{ table.rowNumberStart + indextr }}</vs-td>
            <vs-td>{{ tr.uuid }}</vs-td>
            <vs-td>{{ tr.sales_name }}</vs-td>
            <vs-td>{{ tr.count_invoice }}</vs-td>
            <vs-td>{{ tr.total_remaining_value | formatNumber }}</vs-td>
            <vs-td>{{ tr.of_collection }}</vs-td>
            <vs-td>{{ tr.collection_value | formatNumber }}</vs-td>
            <!-- <vs-td>{{ tr.delta | formatNumber }}</vs-td> -->
            <vs-td>{{ tr.of_no_payment }}</vs-td>
            <vs-td>{{ tr.no_payment_value | formatNumber }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatNumber } from "../../../../utils/common";

export default {
  data() {
    return {
      baseUrl: "api/sfa/v1/collection-plan/list-collection-plan",
      selected_rows: [],
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        is_approved_list: true,
        status: 1,
        rowNumberStart: 1,
      },
    };
  },
  mounted() {
    this.getData({
      length: this.table.length,
      page: this.table.page,
      search: this.table.search,
      order: this.table.order,
      sort: this.table.sort,
      status: 1,
      is_approved_list: true,
    });
  },
  methods: {
    onClickPrint(id) {
      this.$emit("data-print", id);
    },
    onSearchTable(search) {
      this.table.search = search;
      this.table.page = 1;
      this.getData(this.table);
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    handleChangePage(page) {
      this.params.page = page;
      this.getData(this.table);
    },
    handleShow(id) {
      this.$router.push({
        name: "collection-plan-manage-show",
        params: { id: id },
      });
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        status: 1,
      });
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        status: 1,
      });
    },
    getData(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.rowNumberStart = resp.data.row_number_start;
            if (resp.data.records == null) {
              this.table.data = [];
            } else {
              this.table.data = resp.data.records;
            }
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    onClickExportToExcel() {
      if (this.selected_rows.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select at least one collection plan",
          position: "right-top",
        });
        return;
      }

      this.$vs.loading();
      let params = {
        status: 1,
        is_export_excel: true,
        collection_plan_ids: this.selected_rows.map((item) => item.id),
        is_approved_list: true,
      };

      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_MULTIPLE_${currentDate}`;
      moment(this.startDate).format("YYYY-MM-DD") +
        this.$http
          .get(`api/sfa/v1/collection-plan/list-collection-plan`, {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
            params: params,
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([resp]));
              console.log(resp);
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;

              fileLink.setAttribute("download", fileTitle + ".xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          });
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatNumber(val) {
      if (val) {
        return formatNumber(val);
      }
    },
  },
};
</script>
