<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="id"
      :max-height="125"
      :allow-empty="true"
      :custom-label="customLabel"
    >
      <span slot="noResult">Oops! No data found</span>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    isAllowEmpty: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseUrl: "/api/oms/v1/setting-user/territory-master",
      options: [],
      selected: null,
    };
  },
  methods: {
    async getData() {
      this.$vs.loading();
      try {
        const resp = await this.$http.get(this.baseUrl, {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        });
        if (resp.code == 200) {
          var options = [];
          // options.push({
          //   id: 0,
          //   // name: "All",
          //   code: "All",
          // });
          // this.selected = options[0];
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      } catch (error) {
        this.$vs.loading.close();
        console.log(error);
      }
    },
    async setSelected(selected) {
      this.selected = selected;
    },
    customLabel(option) {
      return `${option.code} - ${option.name}`;
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      if (v) {
        this.$emit("data", v.id, v.code, v.name);
      }
    },
  },
  mounted() {
    if (this.isGetData) {
      this.getData();
    }
  },
};
</script>
